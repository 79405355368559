const config: any = {
  holidaysList: [],
  prompts: [],
  holidaysLoaded: false,
  promptsLoaded: false,
  loadingHolidays: false,
  loadingPrompts: false,
  deleting: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  message: '',
};

export default config;
