import { getLogger } from '@vf/services/LoggingService';
import {
  ContactFlowSummary,
  HierarchyGroup,
  HierarchyGroupSummaryList,
  HierarchyStructure,
  PhoneNumberSummary,
  QueueSummary,
  RoutingProfileSummary,
  SecurityProfileSummaryList,
  User,
  UserSummaryList,
} from 'aws-sdk/clients/connect';
import {
  AggregatedAgentMetricsList,
  ConnectPrompt,
  IConnectApi,
  IAggregatedMetrics,
  IContactSearchQuery,
  IContactSearchQueryId,
  IContactSearchQueryResponse,
  IGetSignedUrlRequest,
  IGetSignedUrlResponse,
  IStartContactSearchQueryResponse,
} from '@voicefoundry-cloud/vf-omp-shared';
import { ApiClientBase } from './ApiClientBase';

export class ConnectApiClient extends ApiClientBase implements IConnectApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ConnectApiClient'), useAuth);
  }

  async listPrompts(): Promise<ConnectPrompt[]> {
    const result = await this.get('/api/connect/listPrompts');
    return await result.json();
  }

  async listQueues(): Promise<QueueSummary[]> {
    const result = await this.get('/api/connect/listQueues');
    return result.json();
  }
  async listRoutingProfiles(withQueues?: boolean): Promise<RoutingProfileSummary[]> {
    const result = !withQueues
      ? await this.get('/api/connect/listRoutingProfiles')
      : await this.get(`/api/connect/listRoutingProfiles?withQueues=true`);
    return result.json();
  }
  async listUsers(): Promise<UserSummaryList> {
    const result = await this.get('/api/connect/listUsers');
    return result.json();
  }
  async listContactFlows(): Promise<ContactFlowSummary[]> {
    const result = await this.get('/api/connect/listContactFlows');
    return result.json();
  }
  async listUsersDetailed(): Promise<User[]> {
    const result = await this.get('/api/connect/listUsersDetailed');
    return result.json();
  }
  async listNumbers(): Promise<PhoneNumberSummary[]> {
    const result = await this.get('/api/connect/listNumbers');
    return result.json();
  }
  async listSecurityProfiles(): Promise<SecurityProfileSummaryList> {
    const result = await this.get('/api/connect/listSecurityProfiles');
    return result.json();
  }
  async listUserHierarchyGroupsSummary(): Promise<HierarchyGroupSummaryList> {
    const result = await this.get('/api/connect/listUserHierarchyGroupsSummary');
    return result.json();
  }
  async listUserHierarchyGroupsDetailed(): Promise<HierarchyGroup[]> {
    const result = await this.get('/api/connect/listUserHierarchyGroupsDetailed');
    return result.json();
  }
  async getUserHierarchyDetail(hierarchyId: string): Promise<HierarchyGroup> {
    const result = await this.get(`/api/connect/getHierarchyGroupDetail/${hierarchyId}`);
    return result.json();
  }
  async describeUserHierarchyGroupsStructure(): Promise<HierarchyStructure> {
    const result = await this.get('/api/connect/describeUserHierarchyGroupsStructure');
    return result.json();
  }
  async updateUserRoutingProfile(userId: string, routingProfileId: string): Promise<void> {
    await this.post(`/api/connect/updateUserRoutingProfile?userId=${userId}&routingProfileId=${routingProfileId}`);
  }
  async getCurrentQueueMetricData(): Promise<IAggregatedMetrics> {
    const result = await this.get('/api/connect/getCurrentMetricData/queues');
    return result.json();
  }
  async getCurrentAgentMetricData(): Promise<AggregatedAgentMetricsList> {
    const result = await this.get('/api/connect/getCurrentMetricData/agents');
    return result.json();
  }

  async getCurrentRoutingProfileMetricData(): Promise<IAggregatedMetrics> {
    const result = await this.get('/api/connect/getCurrentMetricData/routing-profiles');
    return result.json();
  }
  // async getHistoricalQueueMetricData(
  //   startDate: string,
  //   endDate: string,
  // ): Promise<VFGetHistoricalMetricDataResponse> {
  //   const result = await this.get(
  //     `/api/connect/getMetricData/queues?startDate=${startDate}&endDate=${endDate}`,
  //   );
  //   return result.json();
  // }
  async startContactSearchQuery(query: IContactSearchQuery): Promise<IStartContactSearchQueryResponse> {
    const result = await this.post('/api/connect/contact-search/query', query);
    return result.json();
  }
  async getContactSearchResults(queryId: IContactSearchQueryId): Promise<IContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/query/${queryId}`);
    return result.json();
  }

  async getRecordingSignedUrl(data: IGetSignedUrlRequest): Promise<IGetSignedUrlResponse> {
    const result = await this.post(`/api/connect/contact-search/get-recording-signed-url`, data);
    return result.json();
  }
  async startContactRecordDetailQuery(contactId: string): Promise<IStartContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/contact-trace-records/details/${contactId}`);
    return result.json();
  }
  async startContactAttributesQuery(contactId: string): Promise<IStartContactSearchQueryResponse> {
    const result = await this.get(`/api/connect/contact-search/contact-trace-records/attributes/${contactId}`);
    return result.json();
  }
}
