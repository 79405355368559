import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { ContactFlowSummary } from 'aws-sdk/clients/connect';
import React, { useContext, useEffect, useReducer } from 'react';
import {
  QueueFlowData,
  Menu,
  OpenCheckTypes,
  QueueFlowActionTypes,
  QueueFlowTypes,
} from '@voicefoundry-cloud/vf-omp-shared';
import queueTreatmentConfig from './QueueTreatmentConfig';
import QueueTreatmentContext from './QueueTreatmentContext';
import { queueTreatmentReducer } from './queueTreatmentReducer';
import { IMessage } from 'types/models/IMessage';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';

export const queueTreatmentState = {
  ...queueTreatmentConfig,
};

const QueueTreatmentContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(queueTreatmentReducer, queueTreatmentState);

  useEffect(() => {
    Promise.all([
      listQueueTreatments(),
      getQueues(),
      getFlows(),
      getAvailableHours(),
      listMenus(),
      getNumbers(),
      getQueueFlowMappings(),
    ]);
  }, [api]);

  useEffect(() => {
    if (state.numbersLoaded && state.queueTreatmentLoaded) {
      const availNumbers = state.numbers.filter(n => !state.queueTreatmentList.map(n => n.key).includes(n.PhoneNumber));
      dispatch({
        type: 'SET_AVAILABLE_NUMBERS',
        payload: availNumbers,
      });
    }
  }, [state.numbers, state.numbersLoaded, state.queueTreatmentList, state.queueTreatmentLoaded]);

  useEffect(() => {
    if (state.availableHoursLoaded) {
      const customHours = state.availableHours.filter(n => n.openCheckType === OpenCheckTypes.CUSTOM);
      dispatch({
        type: 'SET_AVAILABLE_CUSTOM_HOURS',
        payload: customHours,
      });
    }
  }, [state.availableHours, state.availableHoursLoaded]);
  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) => flow.ContactFlowType === 'CUSTOMER_QUEUE'
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: customerQueueFlows,
      });
    }
  }, [state.flowsLoaded]);

  useEffect(() => {
    if (!state.queueFlowMapperLoaded) {
      return;
    }
    const action = async () => {
      try {
        let prompts = await api.helpers.getAllPrompts();

        // Only list prompts available in the mapping file
        const availableNames = Object.entries(state.queueFlowMapper.default.BASIC_HOLD).map(x => x[0]);
        prompts = prompts.filter(p => availableNames.includes(p.Name));

        dispatch({
          type: 'GET_PROMPTS',
          payload: prompts,
        });
      } catch (err) {
        console.warn('Unable to load prompts', err);
      }
    };
    action();
  }, [state.queueFlowMapperLoaded]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const listMenus = async () => {
    dispatch({ type: 'LOADING_MENUS', payload: true });
    const menuList = await api.menu.listMenus();
    dispatch({ type: 'LIST_MENUS', payload: menuList });
  };

  const listQueueTreatments = async () => {
    dispatch({
      type: 'LOADING_QUEUE_TREATMENT',
      payload: true,
    });
    const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
    dispatch({
      type: 'LIST_QUEUE_TREATMENT',
      payload: queueTreatmentList,
    });
    return queueTreatmentList;
  };
  const getQueueFlowMappings = async () => {
    dispatch({
      type: 'LOADING_QUEUE_FLOW_MAPPER',
      payload: true,
    });
    const queueFlowMapper = await api.queueTreatment.getQueueFlowMapping().catch(err => []);
    dispatch({
      type: 'SET_QUEUE_FLOW_MAPPER',
      payload: queueFlowMapper,
    });
  };
  const createMenuScaffolding = async (menu: Menu) => {
    await api.menu.createMenu(menu);
    await listMenus();
  };

  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: numbers,
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: queues,
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getAvailableHours = async () => {
    try {
      const availableHours = await api.hour.getAvailableHoursSelections();
      dispatch({
        type: 'GET_AVAILABLE_HOURS',
        payload: availableHours,
      });
    } catch (err) {
      console.warn('Unable to load available hours', err);
    }
  };
  const getFlows = async () => {
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: flows,
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getQueueTreatment = async (queueTreatmentName: string) => {
    const workingQueueTreatment = await api.queueTreatment.getQueueFlowData(queueTreatmentName);
    dispatch({
      type: 'GET',
      payload: workingQueueTreatment,
    });
    return workingQueueTreatment;
  };
  const deleteQueueTreatment = async (queueTreatmentName: string) => {
    dispatch({
      type: 'DELETING_QUEUE_TREATMENT',
      payload: true,
    });
    try {
      await api.queueTreatment.deleteQueueFlowData(queueTreatmentName);
      await listQueueTreatments();
    } catch (err) {
      dispatch({
        type: 'DELETING_QUEUE_TREATMENT',
        payload: false,
      });
      return false;
    }
    return true;
  };
  const upsertQueueTreatment = (queueTreatment: QueueFlowData) => {
    // @todo CALL API HERE
    dispatch({ type: 'UPSERT', payload: queueTreatment });
    return Promise.resolve(queueTreatment);
  };

  const createQueueTreatmentScaffolding = async (queueTreatment: QueueFlowData) => {
    await api.queueTreatment.createQueueFlowData(queueTreatment);
    await listQueueTreatments();
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: msg });
  };

  const getQueueFlowMapping = (
    interruptSetting: number,
    holdMusic: string,
    actionType: QueueFlowActionTypes,
    id: boolean = false
  ) => {
    if (!state.queueFlowMapper || !state.queueFlowMapper.default || !interruptSetting || !holdMusic || !actionType)
      return '';
    if (actionType === QueueFlowActionTypes.BASIC_HOLD) {
      const map = state.queueFlowMapper.default[QueueFlowTypes.BASIC_HOLD];
      return id ? map[holdMusic].split('/')[3] : map[holdMusic];
    } else {
      const map = state.queueFlowMapper.default[QueueFlowTypes.INTERRUPT_ACTION];
      return id
        ? map[`${interruptSetting}_SECONDS`][holdMusic].split('/')[3]
        : map[`${interruptSetting}_SECONDS`][holdMusic];
    }
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <QueueTreatmentContext.Provider
      value={{
        ...state,
        upsertQueueTreatment,
        getQueueTreatment,
        listQueueTreatments,
        deleteQueueTreatment,
        getQueueMetadata,
        getEntityAttrValue,
        createQueueTreatmentScaffolding,
        createMenuScaffolding,
        setMsg,
        getQueueFlowMapping,
      }}>
      {children}
    </QueueTreatmentContext.Provider>
  );
};

export default QueueTreatmentContextProvider;

export const useQueueTreatmentContext = () => useContext(QueueTreatmentContext);
