type ClosureManagementAction =
  | 'DELETE'
  | 'UPSERT'
  | 'GET'
  | 'LIST'
  | 'GET_QUEUES'
  | 'GET_NUMBERS'
  | 'GET_TIMEZONES'
  | 'LOADING_CLOSURES'
  | 'LOADING_PROMPTS'
  | 'DELETING_CLOSURES'
  | 'LIST_PROMPTS'
  | 'SET_ERROR'
  | 'SET_MESSAGE';

export function reducer(state: any, action: { type: ClosureManagementAction; payload: any }) {
  switch (action.type) {
    case 'DELETE': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingClosures: false,
      };
    }
    case 'DELETING_CLOSURES': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingClosure: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append menu in list instead of listing again
      return state;
    }
    case 'LOADING_CLOSURES': {
      return {
        ...state,
        loadingClosures: action.payload,
      };
    }
    case 'LOADING_PROMPTS': {
      return {
        ...state,
        loadingPrompts: action.payload,
      };
    }
    case 'LIST': {
      return {
        ...state,
        closuresLoaded: true,
        loadingClosures: false,
        closuresList: action.payload.closuresList,
      };
    }
    case 'LIST_PROMPTS': {
      return {
        ...state,
        loadingPrompts: false,
        promptsLoaded: true,
        prompts: action.payload.prompts,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload.queues,
      };
    case 'GET_NUMBERS':
      return {
        ...state,
        numbersLoaded: true,
        numbers: action.payload.numbers,
      };
    case 'GET_TIMEZONES':
      return {
        ...state,
        timezonesLoaded: true,
        timezones: action.payload.timezones,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
}
