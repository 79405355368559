import { QueueFlowMapper } from '@voicefoundry-cloud/vf-omp-shared';
import QueueTreatmentContextPropTypes from './QueueTreatmentContextPropTypes';

export type QueueTreatmentActions =
  | 'UPSERT'
  | 'GET'
  | 'LIST_QUEUE_TREATMENT'
  | 'GET_AVAILABLE_HOURS'
  | 'GET_QUEUES'
  | 'GET_FLOWS'
  | 'GET_PROMPTS'
  | 'SET_CUSTOMER_QUEUE_FLOWS'
  | 'LOADING_QUEUE_TREATMENT'
  | 'DELETING_QUEUE_TREATMENT'
  | 'LIST_MENUS'
  | 'LOADING_MENUS'
  | 'GET_NUMBERS'
  | 'SET_AVAILABLE_NUMBERS'
  | 'SET_AVAILABLE_CUSTOM_HOURS'
  | 'SET_MESSAGE'
  | 'LOADING_QUEUE_FLOW_MAPPER'
  | 'SET_QUEUE_FLOW_MAPPER';

export interface QueueTreatmentAction {
  type: QueueTreatmentActions;
  /**
   * @todo - should add type safety here
   */
  payload: any;
}
export function queueTreatmentReducer(
  state: QueueTreatmentContextPropTypes,
  action: QueueTreatmentAction
): QueueTreatmentContextPropTypes {
  switch (action.type) {
    case 'DELETING_QUEUE_TREATMENT': {
      // @todo Potentially remove queueTreatment from list instead of listing again
      return {
        ...state,
        deletingQueueTreatment: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append queueTreatment in list instead of listing again
      return state;
    }
    case 'GET': {
      // @todo append latest queueTreatment to list so the newest content is there in state
      return {
        ...state,
        workingQueueTreatment: action.payload,
      };
    }
    case 'LOADING_QUEUE_TREATMENT': {
      return {
        ...state,
        loadingQueueTreatments: action.payload,
      };
    }
    case 'LIST_QUEUE_TREATMENT': {
      return {
        ...state,
        queueTreatmentLoaded: true,
        loadingQueueTreatments: false,
        queueTreatmentList: action.payload,
      };
    }
    case 'GET_AVAILABLE_HOURS': {
      return {
        ...state,
        availableHoursLoaded: true,
        availableHoursLoading: false,
        availableHours: action.payload,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload,
      };
    case 'GET_FLOWS':
      return {
        ...state,
        flowsLoaded: true,
        flows: action.payload,
      };
    case 'GET_PROMPTS':
      return {
        ...state,
        promptsLoaded: true,
        prompts: action.payload,
      };
    case 'SET_CUSTOMER_QUEUE_FLOWS':
      return {
        ...state,
        customerQueueFlows: action.payload,
      };
    case 'LIST_MENUS': {
      return {
        ...state,
        menusLoaded: true,
        menuList: action.payload,
      };
    }
    case 'GET_NUMBERS':
      return {
        ...state,
        numbersLoaded: true,
        numbers: action.payload,
      };
    case 'SET_AVAILABLE_NUMBERS':
      return {
        ...state,
        availableNumbers: action.payload,
      };
    case 'SET_AVAILABLE_CUSTOM_HOURS':
      return {
        ...state,
        availableCustomHours: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload,
      };
    case 'LOADING_QUEUE_FLOW_MAPPER': {
      return {
        ...state,
        loadingQueueFlowMapper: action.payload,
      };
    }
    case 'SET_QUEUE_FLOW_MAPPER':
      return {
        ...state,
        queueFlowMapperLoaded: true,
        loadingQueueFlowMapper: false,
        queueFlowMapper: action.payload,
        interruptIntervals: getIntervals(action.payload),
      };
    default:
      return state;
  }
}

/**
 * Parse the available interrupt intervals from the mapping file
 */
function getIntervals(mapper: QueueFlowMapper): number[] {
  return Object.keys(mapper.default.INTERRUPT_ACTION)
    .map(x => parseInt(x.replace('_SECONDS', '')))
    .sort((a, b) => a - b);
}
