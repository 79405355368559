import { AlertProps } from '@mui/material';

export interface CRUD_Helpers {
  openBackdrop: boolean;
  openAlert: boolean;
  openConfirmDeleteFlow: boolean;
  openConfirmDeletePrompt: boolean;
  openConfirmDeleteLanguage: boolean;
  openPromptLanguageDialog: boolean;
  deleteId: string;
  deleteName: string;
  deleteLang: string;
  confirmDeleteMessage: string;
  confirmDeleteTitle: string;
  backdropMessage: string;
  alertMessage: string;
  severity: AlertProps['severity'];
}

export const initialCrudHelpers: CRUD_Helpers = {
  openBackdrop: false,
  openAlert: false,
  openConfirmDeleteFlow: false,
  openPromptLanguageDialog: false,
  openConfirmDeletePrompt: false,
  openConfirmDeleteLanguage: false,
  deleteId: '',
  deleteName: '',
  deleteLang: '',
  confirmDeleteMessage: 'Are you sure you want to delete: ',
  confirmDeleteTitle: 'Delete?',
  backdropMessage: '',
  alertMessage: '',
  severity: 'success',
};

export const validPollyLanguages = [
  'arb',
  'ca-ES',
  'yue-CN',
  'cmn-CN',
  'da-DK',
  'nl-NL',
  'en-AU',
  'en-GB',
  'en-IN',
  'en-NZ',
  'en-ZA',
  'en-US',
  'en-GB-WLS',
  'fr-FR',
  'fr-CA',
  'hi-IN',
  'de-DE',
  'de-AT',
  'is-IS',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'es-ES',
  'es-MX',
  'es-US',
  'sv-SE',
  'tr-TR',
  'cy-GB',
];
