import { ApiClientBase } from './ApiClientBase';
import { HourApi, HoursSelection, HoursVm } from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class HoursApiClient extends ApiClientBase implements HourApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HoursApiClient'), useAuth);
  }

  async deleteHour(id: string): Promise<void> {
    await this.delete(`/api/hours/${id}`);
  }

  async getAllHours(): Promise<any[]> {
    const result = await this.get('/api/hours');
    return await result.json();
  }
  async getAvailableHoursSelections(): Promise<HoursSelection[]> {
    const result = await this.get('/api/hours-available-selections');
    return await result.json();
  }

  async getHour(hourId: string): Promise<HoursVm> {
    const result = await this.get(`/api/hours/${hourId}`);
    return await result.json();
  }

  async postHour(hour: HoursVm): Promise<HoursVm> {
    const result = await this.post('/api/hours', hour);
    return await result.json();
  }

  async putHour(hour: HoursVm): Promise<HoursVm> {
    const result = await this.put('/api/hours', hour);
    return await result.json();
  }
}
