import { OpenCheckTypes } from '@voicefoundry-cloud/vf-omp-shared';

const config: any = {
  hoursList: [],
  hoursLoaded: false,
  loadingHours: false,
  deletingHours: false,
  queues: [],
  queuesLoaded: false,
  numbers: [],
  numbersLoaded: false,
  timezones: [],
  timezonesLoaded: false,
  error: '',
  message: '',
  associationsObj: {
    [OpenCheckTypes.QUEUE]: [],
    [OpenCheckTypes.DNIS]: [],
    [OpenCheckTypes.CUSTOM]: [],
  },
};

export default config;
