type MenuAction =
  | 'DELETE'
  | 'UPSERT'
  | 'GET'
  | 'LIST_MENUS'
  | 'GET_QUEUES'
  | 'GET_FLOWS'
  | 'GET_PROMPTS'
  | 'SET_CUSTOMER_QUEUE_FLOWS'
  | 'LOADING_MENUS'
  | 'DELETING_MENU'
  | 'LOADING_QUEUE_TREATMENT'
  | 'LIST_QUEUE_TREATMENT';

export function menuReducer(state: any, action: { type: MenuAction; payload: any }) {
  switch (action.type) {
    case 'DELETE': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingMenu: false,
      };
    }
    case 'DELETING_MENU': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingMenu: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append menu in list instead of listing again
      return state;
    }
    case 'GET': {
      // @todo append latest menu to list so the newest content is there in state
      return {
        ...state,
        workingMenu: action.payload.workingMenu,
      };
    }
    case 'LOADING_MENUS': {
      return {
        ...state,
        loadingMenus: action.payload,
      };
    }
    case 'LIST_MENUS': {
      return {
        ...state,
        menusLoaded: true,
        loadingMenus: false,
        menuList: action.payload.menuList,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload.queues,
      };
    case 'GET_FLOWS':
      return {
        ...state,
        flowsLoaded: true,
        flows: action.payload.flows,
      };
    case 'GET_PROMPTS':
      return {
        ...state,
        promptsLoaded: true,
        prompts: action.payload.prompts,
      };
    case 'SET_CUSTOMER_QUEUE_FLOWS':
      return {
        ...state,
        customerQueueFlows: action.payload.customerQueueFlows,
      };
    case 'LOADING_QUEUE_TREATMENT': {
      return {
        ...state,
        loadingQueueTreatments: action.payload,
      };
    }
    case 'LIST_QUEUE_TREATMENT': {
      return {
        ...state,
        queueTreatmentLoaded: true,
        loadingQueueTreatments: false,
        queueTreatmentList: action.payload.queueTreatmentList,
      };
    }
    default:
      return state;
  }
}
