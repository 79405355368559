import * as Yup from 'yup';
import { OptionalObjectSchema } from 'yup/lib/object';

export interface IValidationErrors {
  [name: string]: string[];
}

export interface IValidationResult<TModel> {
  isValid: boolean;
  errors: IValidationErrors;
  /**
   * Validation will remove unknown fields
   */
  data: TModel;
}

export const validateSchema = <TModel>(schema: OptionalObjectSchema<any>, data: TModel): IValidationResult<TModel> => {
  const response: IValidationResult<TModel> = {
    isValid: true,
    errors: {},
    data: undefined,
  };
  try {
    response.data = schema.validateSync(data, { abortEarly: false, stripUnknown: true });
  } catch (error: any) {
    response.isValid = false;
    error?.inner?.forEach((e: any) => {
      response.errors[e.path] = e.errors;
    });
  }
  return response;
};

const phoneFormat = () => Yup.string().matches(/^\+[1-9]\d{10,14}$/, 'Phone must be E.164 format: +12223334444');
const contactIdFormat = () =>
  Yup.string().matches(
    /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/,
    'Contact ID must be a UUID'
  );

export const ContactSearchQuerySchema = Yup.object({
  timeRangeStart: Yup.string().required(),
  timeRangeEnd: Yup.string().required(),
  channelFilter: Yup.object({
    voice: Yup.boolean(),
    chat: Yup.boolean(),
    task: Yup.boolean(),
  }).nullable(),
  contactIdFilter: Yup.array().of(contactIdFormat()).nullable(),
  customerPhoneFilter: Yup.array().of(phoneFormat()).nullable(),
  disconnectReasonFilter: Yup.array().of(Yup.string()).nullable(),
  initiationMethodFilter: Yup.array().of(Yup.string()).nullable(),
  systemPhoneNumberFilter: Yup.array().of(Yup.string()).nullable(),
  agentFilter: Yup.array().of(Yup.string()).nullable(),
  queueFilter: Yup.array().of(Yup.string()).nullable(),
  interactionTimeFilter: Yup.object({
    min: Yup.number(),
    max: Yup.number(),
  }).nullable(),
});
