import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { useAppContext } from '@vf/utility/ContextProvider';
import { ContactFlowVm, Prompt, PromptVm } from '@voicefoundry-cloud/vf-omp-shared';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CRUD_Helpers, initialCrudHelpers } from './initialStates';

const cfTemplate: ContactFlowVm = {
  name: '',
  prompts: [],
  attributes: [],
};

interface WorkingLanguagePrompt extends Prompt {
  id: string;
  promptName: string;
  isNewPrompt: boolean;
}

const useConfigSets = () => {
  const api = useContext(ApiContext);

  const [contactFlows, setContactFlows] = useState<ContactFlowVm[]>([]);
  const [workingContactFlow, setWorkingContactFlow] = useState<ContactFlowVm>({
    ...cfTemplate,
  });
  const [workingLanguagePrompt, setWorkingLanguagePrompt] = useState<WorkingLanguagePrompt | undefined>();
  const [crudHelpers, setCrudHelpers] = useState<CRUD_Helpers>(initialCrudHelpers);

  const { pollyLanguageList, config } = useAppContext();
  const supportedOMPLanguages = config.supportedLanguages;
  const supportedPollyLanguages = pollyLanguageList;

  const getDisplayLang = (code: string) => {
    const language = supportedOMPLanguages.find(language => language.code === code);
    return language ? language.name : code;
  };

  useEffect(() => {
    getContactFlows();
  }, [api]);

  const resetCrudHelpers = () => {
    setCrudHelpers({ ...initialCrudHelpers });
  };

  const updateContactFlows = (flow: ContactFlowVm) => {
    const newContactFlows = contactFlows.map(f => {
      if (f.name === flow.name) {
        f.prompts = flow.prompts;
        f.attributes = flow.attributes;
      }
      return f;
    });

    setContactFlows(newContactFlows);
  };

  const getContactFlows = async () => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Retrieving Configuration Sets...' }));
    return api.contactflow
      .getContactFlows()
      .then(data => {
        setContactFlows(data);
        setCrudHelpers(prev => ({ ...prev, openBackdrop: false }));
        return true;
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to load configuration set',
        }));
        return false;
      });
  };

  const createContactFlow = async (flow, prompt, attribute) => {
    console.log('NEW FLOW ', flow);

    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Creating Configuration Set...' }));
    try {
      if (prompt.name.trim() !== '' && attribute.name.trim() !== '') {
        Promise.all([api.contactflow.postPrompt(prompt, flow), api.contactflow.createAttribute(attribute, flow)]).then(
          async () => {
            const cfs = await api.contactflow.getContactFlows();
            const selectedFlow = cfs.find(x => x.name === flow);
            setContactFlows(cfs);
            setWorkingContactFlow(selectedFlow);
            setCrudHelpers(prev => ({
              ...prev,
              openBackdrop: false,
              severity: 'success',
              openAlert: true,
              alertMessage: 'The configuration set created successfully with new prompt & attribute',
            }));
          }
        );
      } else if (prompt.name.trim() !== '') {
        api.contactflow.postPrompt(prompt, flow).then(async () => {
          const cfs = await api.contactflow.getContactFlows();
          const selectedFlow = cfs.find(x => x.name === flow);
          setContactFlows(cfs);
          setWorkingContactFlow(selectedFlow);
          setCrudHelpers(prev => ({
            ...prev,
            openBackdrop: false,
            severity: 'success',
            openAlert: true,
            alertMessage: 'The configuration set created successfully with new prompt',
          }));
        });
      } else if (attribute.name.trim() !== '') {
        api.contactflow.createAttribute(attribute, flow).then(async () => {
          const cfs = await api.contactflow.getContactFlows();
          const selectedFlow = cfs.find(x => x.name === flow);
          setContactFlows(cfs);
          setWorkingContactFlow(selectedFlow);
          setCrudHelpers(prev => ({
            ...prev,
            openBackdrop: false,
            severity: 'success',
            openAlert: true,
            alertMessage: 'The configuration set created successfully with new attribute',
          }));
        });
      }
    } catch (err) {
      console.log(err);
      setCrudHelpers(prev => ({
        ...prev,
        openBackdrop: false,
        severity: 'error',
        openAlert: true,
        alertMessage: 'Unable to create configuration set',
      }));
    }
  };

  const isSelected = name => {
    return workingContactFlow.name === name;
  };

  const getPromptsAttributes = (flow: string) => {
    console.log('OPEN FLOW ', flow);
    setWorkingContactFlow(contactFlows.find(x => x.name === flow));
  };

  const createPrompt = prompt => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Creating Prompt...' }));
    api.contactflow
      .postPrompt(prompt, workingContactFlow.name)
      .then(data => {
        workingContactFlow.prompts = [...workingContactFlow.prompts, data];
        setWorkingContactFlow(workingContactFlow);
        updateContactFlows(workingContactFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The configuration set prompt created successfully.',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to create configuration set prompt',
        }));
      });
  };

  const createAttribute = attribute => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true }));
    api.contactflow
      .createAttribute(attribute, workingContactFlow.name)
      .then(data => {
        workingContactFlow.attributes = [...workingContactFlow.attributes, data];
        setWorkingContactFlow(workingContactFlow);
        updateContactFlows(workingContactFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The configuration set attribute created successfully.',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to create configuration set attribute',
        }));
      });
  };

  const deletePrompt = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Prompt...' }));
    api.contactflow
      .deletePrompt(workingContactFlow.name, id)
      .then(data => {
        workingContactFlow.prompts = workingContactFlow.prompts.filter(obj => obj.id !== id);
        setWorkingContactFlow(workingContactFlow);
        updateContactFlows(workingContactFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The prompt deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete prompt',
        }));
      });
  };

  const deleteAttribute = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Attribute...' }));
    api.contactflow
      .deleteAttribute(workingContactFlow.name, id)
      .then(data => {
        const updatedFlow: ContactFlowVm = {
          ...workingContactFlow,
          attributes: (workingContactFlow.attributes = workingContactFlow.attributes.filter(obj => obj.id !== id)),
        };
        setWorkingContactFlow(updatedFlow);
        updateContactFlows(updatedFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The attribute deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete attribute',
        }));
      });
  };

  const updateAttribute = (id: string, attributeValue) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Updating Attribute...' }));
    const attributeVm = workingContactFlow.attributes.find(e => e.id === id);
    attributeVm.data = attributeValue;

    api.contactflow
      .updateAttribute(attributeVm, workingContactFlow.name)
      .then(data => {
        workingContactFlow.attributes = workingContactFlow.attributes.map(attribute => {
          if (attribute.id === data.id) {
            attribute.data = data.data;
          }

          return attribute;
        });
        setWorkingContactFlow(workingContactFlow);
        updateContactFlows(workingContactFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The attribute updated succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to update attribute',
        }));
      });
  };

  const updatePromptStatus = (id: string, status: boolean) => {
    const promptVm = workingContactFlow.prompts.find(e => e.id === id);
    promptVm.disabled = status;
    putPrompt(promptVm);
  };

  const updatePrompt = (id: string, prompt: Prompt, backdropMessage?: string) => {
    const promptVm = workingContactFlow.prompts.find(e => e.id === id);
    const updated = { ...promptVm, data: [...promptVm.data.filter(obj => obj.lang !== prompt.lang), prompt] };
    putPrompt(updated, backdropMessage);
  };

  const updatePromptByLng = (id: string, lang: string) => {
    const promptVm = workingContactFlow.prompts.find(e => e.id === id);
    const newPrompt: PromptVm = { ...promptVm, data: promptVm.data.filter(obj => obj.lang !== lang) };
    putPrompt(newPrompt);
  };

  const deletePromptLanguage = (promptId: string, langCode: string) => {
    const promptVm = workingContactFlow.prompts.find(prompt => prompt.id === promptId);
    const langRemoved = { ...promptVm, data: [...promptVm.data.filter(lp => lp.lang !== langCode)] };
    putPrompt(langRemoved, `Deleting ${getDisplayLang(langCode)} (${langCode}) from ${promptVm.name}`);
  };

  const putPrompt = (prompt: PromptVm, backdropMessage?: string) => {
    setCrudHelpers(prev => ({
      ...prev,
      openBackdrop: true,
      backdropMessage: backdropMessage || 'Updating Prompt Set...',
    }));
    api.contactflow
      .putPrompt(prompt, workingContactFlow.name)
      .then(data => {
        const updatedFlow: ContactFlowVm = {
          ...workingContactFlow,
          prompts: [
            ...workingContactFlow.prompts.map(prompt => {
              if (prompt.id === data.id) {
                prompt.data = data.data.sort((a, b) => (a.lang > b.lang ? 1 : -1));
                prompt.name = data.name;
                prompt.disabled = data.disabled;
              }

              return prompt;
            }),
          ],
        };
        setWorkingContactFlow(updatedFlow);
        updateContactFlows(updatedFlow);
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The prompt updated succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to update prompt',
        }));
      });
  };

  const deleteContactFlow = (id: string) => {
    setCrudHelpers(prev => ({ ...prev, openBackdrop: true, backdropMessage: 'Deleting Configuration Set...' }));
    api.contactflow
      .deleteContactFlow(id)
      .then(data => {
        setContactFlows([...contactFlows.filter(obj => obj.name !== id)]);
        if (workingContactFlow.name === id) {
          setWorkingContactFlow({ ...cfTemplate });
        }
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'success',
          openAlert: true,
          alertMessage: 'The config set deleted succesfully',
        }));
      })
      .catch(error => {
        setCrudHelpers(prev => ({
          ...prev,
          openBackdrop: false,
          severity: 'error',
          openAlert: true,
          alertMessage: 'Unable to delete config set',
        }));
      });
  };

  return {
    contactFlows,
    workingContactFlow,
    crudHelpers,
    cfTemplate,
    workingLanguagePrompt,
    supportedOMPLanguages,
    supportedPollyLanguages,
    getDisplayLang,
    setWorkingLanguagePrompt,
    setCrudHelpers,
    resetCrudHelpers,
    createAttribute,
    createContactFlow,
    createPrompt,
    getContactFlows,
    setWorkingContactFlow,
    getPromptsAttributes,
    updateAttribute,
    updateContactFlows,
    updatePrompt,
    updatePromptByLng,
    updatePromptStatus,
    deleteContactFlow,
    deleteAttribute,
    deletePrompt,
    deletePromptLanguage,
    isSelected,
  };
};

export type UseConfigSetsType = ReturnType<typeof useConfigSets>;
// Context
export const ConfigSetContext = createContext<UseConfigSetsType | null>(null);
export const useConfigSetContext = () => useContext(ConfigSetContext)!;

export const ConfigSetContextProvider = ({ children }: { children: ReactNode }) => (
  <ConfigSetContext.Provider value={useConfigSets()}>{children}</ConfigSetContext.Provider>
);
