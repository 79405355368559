/**
 * @todo: dont use any, -ib 7/29/2022
 */
const entryPointsConfig: any = {
  entryPointList: [],
  entryPointsLoaded: false,
  loadingEntryPoints: false,
  deletingEntryPoint: false,
  workingEntryPoint: null,
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  customerQueueFlows: [],
  availableHours: [],
  availableHoursLoaded: false,
  menuList: [],
  menusLoaded: false,
  numbers: [],
  availableNumbers: [],
  numbersLoaded: false,
  availableCustomHours: [],
  prompts: [],
  promptsLoaded: false,
  message: '',
};

export default entryPointsConfig;
