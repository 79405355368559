const menuConfig: any = {
  menuList: [],
  menusLoaded: false,
  loadingMenu: false,
  deletingMenu: false,
  workingMenu: null,
  sampleAttribute: 'testing',
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  customerQueueFlows: [],
};

export default menuConfig;
