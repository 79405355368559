type ThemeSetting = 'UPDATE_AUTH_USER' | 'SET_INITIAL_PATH';

type ConfigSettingAction =
  | 'SET_DEFAULT_GLOBAL_CONFIG'
  | 'SET_AVAILABLE_FEATURES'
  | 'ENABLE_TENANCY'
  | 'SET_TENANCY_SETTING';

export function contextReducer(state: any, action: { type: ConfigSettingAction | ThemeSetting; payload: any }) {
  switch (action.type) {
    case 'SET_DEFAULT_GLOBAL_CONFIG': {
      return {
        ...state,
        config: action.payload,
      };
    }

    case 'ENABLE_TENANCY': {
      return {
        ...state,
        tenancyEnabled: action.payload,
      };
    }
    case 'SET_TENANCY_SETTING': {
      return {
        ...state,
        tenancyOn: action.payload,
      };
    }
    case 'SET_AVAILABLE_FEATURES': {
      return {
        ...state,
        featureList: action.payload,
      };
    }
    case 'SET_INITIAL_PATH': {
      return {
        ...state,
        initialPath: action.payload,
      };
    }
    case 'UPDATE_AUTH_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    default:
      return state;
  }
}
