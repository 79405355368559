import { ENTITY_TYPES } from 'contexts/SharedContextModels';
import { IMessage } from 'types/models/IMessage';

const queueTreatmentConfig = {
  availableHours: [],
  availableHoursLoaded: false,
  availableHoursLoading: false,
  queueTreatmentList: [],
  queueTreatmentLoaded: false,
  loadingQueueTreatments: false,
  deletingQueueTreatment: false,
  workingQueueTreatment: null,
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  customerQueueFlows: [],
  menuList: [],
  menusLoaded: false,
  numbers: [],
  availableNumbers: [],
  numbersLoaded: false,
  availableCustomHours: [],
  prompts: [],
  promptsLoaded: false,
  message: '',
  loadingQueueFlowMapper: true,
  queueFlowMapperLoaded: false,
  queueFlowMapper: null,
  listLoaded: false,
  msg: undefined,
  interruptIntervals: [],
  setMsg: (msg: IMessage) => {},
  getQueueFlowMapping: () => '',
  getEntityAttrValue: (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr?: string) => '',
};

export default queueTreatmentConfig;
