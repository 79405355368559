import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useContext, useEffect, useReducer } from 'react';
import { OpenCheckTypes } from '@voicefoundry-cloud/vf-omp-shared';
import config from './config';
import HoursManagementContext from './HoursManagementContext';
import { IMessage } from 'types/models/IMessage';
import { hoursReducer } from './hoursReducer';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
export const hoursState = {
  ...config,
};
const HoursManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(hoursReducer, hoursState);

  useEffect(() => {
    listHours();
    getQueues();
    getNumbers();
    getTimezones();
  }, [api]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const listHours = async () => {
    dispatch({ type: 'LOADING_HOURS', payload: true });
    try {
      const hoursList = await api.hour.getAllHours();
      dispatch({ type: 'LIST', payload: { hoursList } });
    } catch (err) {
      dispatch({ type: 'LOADING_HOURS', payload: false });
      dispatch({
        type: 'SET_ERROR',
        payload: { error: 'Server error while loading hours data' },
      });
    }
  };

  useEffect(() => {
    if (state.hoursList.length === 0) return;
    for (const hours of state.hoursList) {
      for (const association of hours.queues) {
        dispatch({
          type: 'ADD_ASSOCIATION_KEY',
          payload: association,
        });
      }
    }
  }, [state.hoursList]);

  const addAssociationToDict = (association: { key: string; type: OpenCheckTypes }): void => {
    console.log('Adding association ', association);
    dispatch({
      type: 'ADD_ASSOCIATION_KEY',
      payload: association,
    });
  };
  const removeAssociationFromDict = (association: { key: string; type: OpenCheckTypes }): void => {
    dispatch({
      type: 'REMOVE_ASSOCIATION_KEY',
      payload: association,
    });
  };

  useEffect(() => {
    console.log(state.associationsDict);
  }, [state.associationsDict]);

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <HoursManagementContext.Provider
      value={{
        ...state,
        getQueueMetadata,
        setMsg,
        getEntityAttrValue,
        listHours,
        addAssociationToDict,
        removeAssociationFromDict,
      }}>
      {children}
    </HoursManagementContext.Provider>
  );
};

export default HoursManagementContextProvider;
