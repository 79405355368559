import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import AppContext from './AppContext';
import { contextReducer } from './ContextReducer';
import { pollyLanguageList } from './pollyLanguageList';
import RFC5646_LANGUAGE_MAP from 'shared/constants/RFC5646_LANGUAGE_MAP';
import ConfigurationService from '../../services/ConfigurationService';
import AppContextPropsType from '@vf/utility/ContextProvider/AppContextPropsType';

const buildContextState = () => {
  return {
    user: null,
    tenancyEnabled: false,
    tenancyOn: false,
    featureList: [],
    config: ConfigurationService,
    initialPath: '',
  };
};

const ContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [configIsLoaded, setConfigIsLoaded] = useState<boolean>(false);
  const config: ConfigurationService = new ConfigurationService();
  const initializeConfig = useCallback(async () => {
    if (configIsLoaded) return;
    await config.load();
    setConfig(config);
    setConfigIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeConfig();
  }, [initializeConfig]);

  const [state, dispatch] = useReducer(contextReducer, buildContextState());
  useEffect(() => {
    console.log('Features ', state.featureList);
  }, [state.featureList]);
  const setConfig = (config: ConfigurationService) => {
    dispatch({
      type: 'SET_DEFAULT_GLOBAL_CONFIG',
      payload: config,
    });
  };
  const updateAuthUser = useCallback(user => {
    dispatch({ type: 'UPDATE_AUTH_USER', payload: user });
  }, []);

  const setInitialPath = useCallback(path => {
    dispatch({ type: 'SET_INITIAL_PATH', payload: path });
  }, []);

  const enableTenancy = useCallback((bool: boolean) => {
    dispatch({ type: 'ENABLE_TENANCY', payload: bool });
  }, []);
  const setTenancySetting = useCallback((bool: boolean) => {
    dispatch({ type: 'SET_TENANCY_SETTING', payload: bool });
  }, []);
  const setAvailableFeatures = (featureList: string[]) => {
    dispatch({
      type: 'SET_AVAILABLE_FEATURES',
      payload: featureList,
    });
  };

  const checkFeatureAccess = (feature: string) => state.featureList.includes(feature);

  const getDisplayLang = (code: string) => {
    return RFC5646_LANGUAGE_MAP[code] || code;
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        pollyLanguageList,
        getDisplayLang,
        setInitialPath,
        updateAuthUser,
        enableTenancy,
        setTenancySetting,
        setAvailableFeatures,
        checkFeatureAccess,
      }}>
      {/* Need to wait for config to be loaded since we make API calls on init */}
      {configIsLoaded && children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext<AppContextPropsType>(AppContext);

export default ContextProvider;
