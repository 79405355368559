import { ApiClientBase } from './ApiClientBase';
import { HolidayApi, HolidayPromptVm, HolidayVm, PromptVm } from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class HolidaysApiClient extends ApiClientBase implements HolidayApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('HolidaysApiClient'), useAuth);
  }

  async deleteHoliday(id: string): Promise<void> {
    await this.delete(`/api/holidays/${id}`);
  }

  async deletePrompt(id: string): Promise<void> {
    await this.delete(`/api/holidays/prompts/${id}`);
  }

  async getAllHolidays(): Promise<any[]> {
    const result = await this.get('/api/holidays');
    return await result.json();
  }

  async getHolidayPrompts(): Promise<HolidayPromptVm> {
    const result = await this.get('/api/holidays/prompts');
    return await result.json();
  }

  async postHoliday(holiday: HolidayVm): Promise<HolidayVm> {
    const result = await this.post('/api/holidays', holiday);
    return await result.json();
  }

  async postPrompt(prompt: PromptVm): Promise<PromptVm> {
    const result = await this.post('/api/holidays/prompts', prompt);
    return await result.json();
  }

  async putHoliday(holiday: HolidayVm): Promise<HolidayVm> {
    const result = await this.put('/api/holidays', holiday);
    return await result.json();
  }

  async putPrompt(prompt: PromptVm): Promise<PromptVm> {
    const result = await this.put('/api/holidays/prompts', prompt);
    return await result.json();
  }
}
