import { ApiClientBase } from './ApiClientBase';
import { getLogger } from '@vf/services/LoggingService';
import { Voice, AudioStream } from 'aws-sdk/clients/polly';
import {
  IContactFlowsApi,
  ContactFlowVm,
  GetPromptAudioRequest,
  PromptVm,
  AttributeVm,
  ConfigurationSetIE,
  IMappingSyncData,
} from '@voicefoundry-cloud/vf-omp-shared';

export class ContactFlowsApiClient extends ApiClientBase implements IContactFlowsApi {
  pollyVoicesPerLang = new Map<string, Voice[]>();
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ContactFlowsApiClient'), useAuth);
    //pre-populate Polly voices for English and Spanish
    this.getListOfPollyVoices('en-US');
    this.getListOfPollyVoices('es-US');
  }

  async deleteAttribute(flowId: string, attrId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}/attributes/${attrId}`);
  }

  async deleteContactFlow(flowId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}`);
  }

  async deletePrompt(flowId: string, promptId: string): Promise<void> {
    await this.delete(`/api/contactFlows/${flowId}/prompts/${promptId}`);
  }

  async getContactFlows(): Promise<any[]> {
    const result = await this.get('/api/contactFlows');
    return await result.json();
  }

  async getContactFlow(flowId: string): Promise<ContactFlowVm> {
    const result = await this.get(`/api/contactFlows/${flowId}`);
    return await result.json();
  }

  async getPromptAudio(body: GetPromptAudioRequest): Promise<{ AudioStream: AudioStream }> {
    const result = await this.post('/api/contactFlows/getPromptAudio', body);
    const resultConv = await result.json();
    return resultConv;
  }

  async getListOfPollyVoices(lang: string): Promise<Voice[]> {
    if (!this.pollyVoicesPerLang.has(lang)) {
      const result = await this.get(`/api/contactFlows/getPollyVoices/${lang}`);
      const resultJson = await result.json();
      this.pollyVoicesPerLang.set(lang, resultJson);
    }
    return this.pollyVoicesPerLang.get(lang) || [];
  }

  async createAttribute(attr: AttributeVm, contactFlow: string): Promise<AttributeVm> {
    const result = await this.post(`/api/contactFlows/${contactFlow}/attributes`, attr);
    return await result.json();
  }

  async postPrompt(prompt: PromptVm, flowId: string): Promise<PromptVm> {
    const result = await this.post(`/api/contactFlows/${flowId}/prompts`, prompt);
    return await result.json();
  }

  async updateAttribute(attr: AttributeVm, contactFlow: string): Promise<AttributeVm> {
    const result = await this.put(`/api/contactFlows/${contactFlow}/attributes`, attr);
    return await result.json();
  }

  async putPrompt(prompt: PromptVm, flowId: string): Promise<PromptVm> {
    const result = await this.put(`/api/contactFlows/${flowId}/prompts`, prompt);
    return await result.json();
  }
  async importConfigurationSetData(data: ConfigurationSetIE[]): Promise<any> {
    const result = await this.post(`/api/contactFlows/import/config-set-data`, data);
    return await result.json();
  }
  async importMappingSyncData(data: IMappingSyncData): Promise<IMappingSyncData> {
    const result = await this.post(`/api/sync/import-mappings-sync-data`, data);
    return await result.json();
  }
  async exportLocalMappingSyncData(): Promise<IMappingSyncData> {
    const result = await this.get(`/api/sync/export-local-mappings-sync-data`);
    return await result.json();
  }
  async getMappingSyncData(): Promise<IMappingSyncData> {
    const result = await this.get(`/api/sync/mappings-sync-data`);
    return await result.json();
  }
}
