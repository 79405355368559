type EntryPointAction =
  | 'DELETE'
  | 'UPSERT'
  | 'GET'
  | 'LIST_ENTRY_POINTS'
  | 'GET_AVAILABLE_HOURS'
  | 'GET_QUEUES'
  | 'GET_FLOWS'
  | 'GET_PROMPTS'
  | 'SET_CUSTOMER_QUEUE_FLOWS'
  | 'LOADING_ENTRY_POINTS'
  | 'DELETING_ENTRY_POINT'
  | 'LIST_MENUS'
  | 'LOADING_MENUS'
  | 'GET_NUMBERS'
  | 'SET_AVAILABLE_NUMBERS'
  | 'SET_AVAILABLE_CUSTOM_HOURS'
  | 'SET_MESSAGE'
  | 'LOADING_QUEUE_TREATMENT'
  | 'LIST_QUEUE_TREATMENT';

export function entryPointReducer(state: any, action: { type: EntryPointAction; payload: any }) {
  switch (action.type) {
    case 'DELETE': {
      // @todo Potentially remove entryPoint from list instead of listing again
      return {
        ...state,
        deletingEntryPoint: false,
      };
    }
    case 'DELETING_ENTRY_POINT': {
      // @todo Potentially remove entryPoint from list instead of listing again
      return {
        ...state,
        deletingEntryPoint: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append entryPoint in list instead of listing again
      return state;
    }
    case 'GET': {
      // @todo append latest entryPoint to list so the newest content is there in state
      return {
        ...state,
        workingEntryPoint: action.payload.workingEntryPoint,
      };
    }
    case 'LOADING_ENTRY_POINTS': {
      return {
        ...state,
        loadingEntryPoints: action.payload,
      };
    }
    case 'LIST_ENTRY_POINTS': {
      return {
        ...state,
        entryPointsLoaded: true,
        loadingEntryPoints: false,
        entryPointList: action.payload.entryPointList,
      };
    }
    case 'GET_AVAILABLE_HOURS': {
      return {
        ...state,
        availableHoursLoaded: true,
        loadingAvailableHours: false,
        availableHours: action.payload.availableHours,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload.queues,
      };
    case 'GET_FLOWS':
      return {
        ...state,
        flowsLoaded: true,
        flows: action.payload.flows,
      };
    case 'GET_PROMPTS':
      return {
        ...state,
        promptsLoaded: true,
        prompts: action.payload.prompts,
      };
    case 'SET_CUSTOMER_QUEUE_FLOWS':
      return {
        ...state,
        customerQueueFlows: action.payload.customerQueueFlows,
      };
    case 'LIST_MENUS': {
      return {
        ...state,
        menusLoaded: true,
        menuList: action.payload.menuList,
      };
    }
    case 'GET_NUMBERS':
      return {
        ...state,
        numbersLoaded: true,
        numbers: action.payload.numbers,
      };
    case 'SET_AVAILABLE_NUMBERS':
      return {
        ...state,
        availableNumbers: action.payload,
      };
    case 'SET_AVAILABLE_CUSTOM_HOURS':
      return {
        ...state,
        availableCustomHours: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    case 'LOADING_QUEUE_TREATMENT': {
      return {
        ...state,
        loadingQueueTreatments: action.payload,
      };
    }
    case 'LIST_QUEUE_TREATMENT': {
      return {
        ...state,
        queueTreatmentLoaded: true,
        loadingQueueTreatments: false,
        queueTreatmentList: action.payload.queueTreatmentList,
      };
    }
    default:
      return state;
  }
}
