import { ContactFlowSummary } from 'aws-sdk/clients/connect';
import React, { useContext, useEffect, useReducer } from 'react';

import { IMessage } from 'types/models/IMessage';
import entryPointConfig from './ContactEntryPointsConfig';
import EntryPointManagementContext from './ContactEntryPointsContext';
import { entryPointReducer } from './entryPointReducer';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { EntryFlowData, Menu, OpenCheckTypes } from '@voicefoundry-cloud/vf-omp-shared';
import { VF_DEFAULT_QUEUE_FLOW_NAME_REGEX } from '../../defaultUtils/regexUtils';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';

export const entryPointState = {
  ...entryPointConfig,
};

const EntryPointManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(entryPointReducer, entryPointState);

  useEffect(() => {
    Promise.all([
      listEntryPoints(),
      getQueues(),
      getFlows(),
      getPrompts(),
      getAvailableHours(),
      listMenus(),
      getNumbers(),
      listQueueTreatments(),
    ]);
  }, [api]);

  useEffect(() => {
    if (state.numbersLoaded && state.entryPointsLoaded) {
      const availNumbers = state.numbers.filter(n => !state.entryPointList.map(n => n.key).includes(n.PhoneNumber));
      dispatch({
        type: 'SET_AVAILABLE_NUMBERS',
        payload: availNumbers,
      });
    }
  }, [state.numbers, state.numbersLoaded, state.entryPointList, state.entryPointsLoaded]);

  useEffect(() => {
    if (state.availableHoursLoaded) {
      const customHours = state.availableHours.filter(n => n.openCheckType === OpenCheckTypes.CUSTOM);
      dispatch({
        type: 'SET_AVAILABLE_CUSTOM_HOURS',
        payload: customHours,
      });
    }
  }, [state.availableHours, state.availableHoursLoaded]);
  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) =>
          flow.ContactFlowType === 'CUSTOMER_QUEUE' && !VF_DEFAULT_QUEUE_FLOW_NAME_REGEX.test(flow.Name)
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: { customerQueueFlows },
      });
    }
  }, [state.flowsLoaded]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const listMenus = async () => {
    dispatch({ type: 'LOADING_MENUS', payload: true });
    const menuList = await api.menu.listMenus();
    dispatch({ type: 'LIST_MENUS', payload: { menuList } });
  };

  const listEntryPoints = async () => {
    dispatch({ type: 'LOADING_ENTRY_POINTS', payload: true });
    const entryPointList = await api.entryPoint.listEntryPointData();
    dispatch({
      type: 'LIST_ENTRY_POINTS',
      payload: { entryPointList },
    });
  };
  const listQueueTreatments = async () => {
    dispatch({ type: 'LOADING_QUEUE_TREATMENT', payload: true });
    const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
    dispatch({
      type: 'LIST_QUEUE_TREATMENT',
      payload: { queueTreatmentList },
    });
  };
  const createMenuScaffolding = async (menu: Menu) => {
    await api.menu.createMenu(menu);
    await listMenus();
  };

  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getAvailableHours = async () => {
    try {
      const availableHours = await api.hour.getAvailableHoursSelections();
      dispatch({
        type: 'GET_AVAILABLE_HOURS',
        payload: {
          availableHours,
        },
      });
    } catch (err) {
      console.warn('Unable to load available hours', err);
    }
  };
  const getFlows = async () => {
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: {
          flows,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getPrompts = async () => {
    try {
      const prompts = await api.helpers.getAllPrompts();
      dispatch({
        type: 'GET_PROMPTS',
        payload: {
          prompts,
        },
      });
    } catch (err) {
      console.warn('Unable to load prompts', err);
    }
  };
  const getEntryPoint = async (entryPointName: string) => {
    const workingEntryPoint = await api.entryPoint.getEntryPointData(entryPointName);
    dispatch({ type: 'GET', payload: { workingEntryPoint } });
  };
  const deleteEntryPoint = async (entryPointName: string) => {
    dispatch({ type: 'DELETING_ENTRY_POINT', payload: true });
    try {
      await api.entryPoint.deleteEntryPointData(entryPointName);
      dispatch({ type: 'DELETE', payload: { entryPointName } });
      await listEntryPoints();
    } catch (err) {
      dispatch({ type: 'DELETING_ENTRY_POINT', payload: false });
    }
  };
  const upsertEntryPoint = (entryPoint: EntryFlowData) => {
    // @todo CALL API HERE

    dispatch({ type: 'UPSERT', payload: { entryPoint } });
  };

  const createEntryPointScaffolding = async (entryPoint: EntryFlowData) => {
    await api.entryPoint.createEntryPointData(entryPoint);
    await listEntryPoints();
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <EntryPointManagementContext.Provider
      value={{
        ...state,
        upsertEntryPoint,
        getEntryPoint,
        listEntryPoints,
        deleteEntryPoint,
        getQueueMetadata,
        getEntityAttrValue,
        createEntryPointScaffolding,
        createMenuScaffolding,
        setMsg,
      }}>
      {children}
    </EntryPointManagementContext.Provider>
  );
};

export default EntryPointManagementContextProvider;
