import { find } from 'lodash';
import {
  AppFeaturePermissions,
  ConnectResourcePermissions,
  PermissionsDict,
  PermissionsDictList,
  PermissionType,
  Token,
  UMIFeatureAction,
  UMIPolicy,
  UMIUserList,
  UMIUserSummary,
} from '../model';

export class UserPermissionsManager {
  constructor() {}
  static getAccessibleFeatures(rolePolicy: UMIPolicy, tenantPolicy?: UMIPolicy) {
    return tenantPolicy
      ? UserPermissionsManager.parseAccessibleTenantAndRoleFeatures(rolePolicy, tenantPolicy)
      : UserPermissionsManager.parseAccessibleRoleFeatures(rolePolicy);
  }
  static parseAccessibleTenantAndRoleFeatures(rolePolicy: UMIPolicy, tenantPolicy: UMIPolicy) {
    const tenantFeatures = Object.keys(tenantPolicy.app)
      .filter(featureKey => rolePolicy.app[featureKey].featureOn)
      .map(featureKey => featureKey);
    return Object.keys(rolePolicy.app)
      .filter(featureKey => rolePolicy.app[featureKey].featureOn)
      .map(featureKey => featureKey)
      .filter(featureKey => tenantFeatures.includes(featureKey));
  }
  static parseAccessibleTenantAndRoleConnectFeatures(rolePolicy: UMIPolicy, tenantPolicy: UMIPolicy) {
    const tenantFeatures = Object.keys(tenantPolicy.connect)
      .filter(featureKey => rolePolicy.connect[featureKey].featureOn)
      .map(featureKey => featureKey);
    return Object.keys(rolePolicy.connect)
      .filter(featureKey => rolePolicy.connect[featureKey].featureOn)
      .map(featureKey => featureKey)
      .filter(featureKey => tenantFeatures.includes(featureKey));
  }
  static parseAccessibleRoleFeatures(rolePolicy: UMIPolicy) {
    return Object.keys(rolePolicy.app)
      .filter(featureKey => rolePolicy.app[featureKey].featureOn)
      .map(featureKey => featureKey);
  }
  static parseAccessibleRoleConnectFeatures(rolePolicy: UMIPolicy) {
    return Object.keys(rolePolicy.connect)
      .filter(featureKey => rolePolicy.connect[featureKey].featureOn)
      .map(featureKey => featureKey);
  }
  static parsePermissionsKey(type: PermissionType, featurePermissions: AppFeaturePermissions): any {
    return featurePermissions && featurePermissions.resource
      ? Object.keys(featurePermissions.resource)
          .filter(fp => fp && featurePermissions.resource[fp].includes(type))
          .map(fp => fp)
      : [];
  }
  static parseAccessibleResources(featurePermissions: AppFeaturePermissions): PermissionsDictList {
    return {
      [PermissionType.READ]: UserPermissionsManager.parsePermissionsKey(PermissionType.READ, featurePermissions),
      [PermissionType.WRITE]: UserPermissionsManager.parsePermissionsKey(PermissionType.WRITE, featurePermissions),
      [PermissionType.DELETE]: UserPermissionsManager.parsePermissionsKey(PermissionType.DELETE, featurePermissions),
      [PermissionType.DENY]: UserPermissionsManager.parsePermissionsKey(PermissionType.DENY, featurePermissions),
      [PermissionType.ALL]: UserPermissionsManager.parsePermissionsKey(PermissionType.ALL, featurePermissions),
    };
  }
  static parseAccessibleUsers(users: UMIUserSummary[], featurePermissions: AppFeaturePermissions): UMIUserList {
    const userPerms = UserPermissionsManager.parseAccessibleResources(featurePermissions);
    return users
      .map(user => {
        return {
          user: user,
          permissions: {
            [PermissionType.READ]: UserPermissionsManager.resourceHasAccess(
              user.userName,
              userPerms,
              PermissionType.READ
            ),
            [PermissionType.WRITE]: UserPermissionsManager.resourceHasAccess(
              user.userName,
              userPerms,
              PermissionType.WRITE
            ),
            [PermissionType.DELETE]: UserPermissionsManager.resourceHasAccess(
              user.userName,
              userPerms,
              PermissionType.DELETE
            ),
          },
        };
      })
      .filter(user => user[PermissionType.READ]);
  }
  static filterTenantResources(resources: any[], tenantKey: string, attr?: string): any[] {
    return attr
      ? resources.filter(r => r[attr].tenantKey === tenantKey || !r[attr].tenantKey || r[attr].global)
      : resources.filter(r => r.tenantKey === tenantKey || !r.tenantKey || r.global);
  }
  static filterAccessibleResources(
    list: any[],
    resourceType: string,
    featurePermissions: AppFeaturePermissions | ConnectResourcePermissions,
    attrToCheck?: string
  ): any[] {
    const userPerms = UserPermissionsManager.parseAccessibleResources(featurePermissions);
    return list
      .map(resource => {
        if (!resource.permissions) {
          return {
            [resourceType]: resource,
            permissions: UserPermissionsManager.resourceHasAccess(resource[attrToCheck], userPerms, PermissionType.ALL)
              ? {
                  [PermissionType.READ]: true,
                  [PermissionType.WRITE]: true,
                  [PermissionType.DELETE]: true,
                  [PermissionType.ALL]: true,
                }
              : {
                  [PermissionType.READ]: UserPermissionsManager.resourceHasAccess(
                    resource[attrToCheck],
                    userPerms,
                    PermissionType.READ
                  ),
                  [PermissionType.WRITE]: UserPermissionsManager.resourceHasAccess(
                    resource[attrToCheck],
                    userPerms,
                    PermissionType.WRITE
                  ),
                  [PermissionType.DELETE]: UserPermissionsManager.resourceHasAccess(
                    resource[attrToCheck],
                    userPerms,
                    PermissionType.DELETE
                  ),
                  [PermissionType.ALL]: false,
                },
          };
        } else {
          const newPerms = UserPermissionsManager.resourceHasAccess(
            resource[attrToCheck],
            userPerms,
            PermissionType.ALL
          )
            ? {
                [PermissionType.READ]: true,
                [PermissionType.WRITE]: true,
                [PermissionType.DELETE]: true,
                [PermissionType.ALL]: true,
              }
            : {
                [PermissionType.READ]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.READ
                ),
                [PermissionType.WRITE]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.WRITE
                ),
                [PermissionType.DELETE]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.DELETE
                ),
                [PermissionType.ALL]: false,
              };
          return {
            [resourceType]: resource,
            permissions: {
              [PermissionType.READ]: newPerms.READ,
              [PermissionType.WRITE]: resource.permissions.WRITE ? newPerms.WRITE : false,
              [PermissionType.DELETE]: resource.permissions.DELETE ? newPerms.DELETE : false,
              [PermissionType.ALL]: resource.permissions.ALL ? newPerms.ALL : false,
            },
          };
        }
      })
      .filter(user => user.permissions[PermissionType.READ]);
  }
  static filterAccessibleResourcesByType<T>(
    list: any[],
    featurePermissions: AppFeaturePermissions | ConnectResourcePermissions,
    attrToCheck?: string
  ): any[] {
    const userPerms = UserPermissionsManager.parseAccessibleResources(featurePermissions);
    return list
      .map(resource => {
        return {
          ...resource,
          permissions: UserPermissionsManager.resourceHasAccess(resource[attrToCheck], userPerms, PermissionType.ALL)
            ? {
                [PermissionType.READ]: true,
                [PermissionType.WRITE]: true,
                [PermissionType.DELETE]: true,
                [PermissionType.ALL]: true,
              }
            : {
                [PermissionType.READ]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.READ
                ),
                [PermissionType.WRITE]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.WRITE
                ),
                [PermissionType.DELETE]: UserPermissionsManager.resourceHasAccess(
                  resource[attrToCheck],
                  userPerms,
                  PermissionType.DELETE
                ),
              },
        };
      })
      .filter(user => user.permissions[PermissionType.READ]) as T[];
  }

  static resourceHasAccess(
    resource: string | string[],
    permDict: PermissionsDictList,
    permType: PermissionType
  ): boolean {
    if (typeof resource === 'string') {
      if (permDict.DENY && permDict.DENY.includes(resource)) {
        return false;
      }
      if (permDict[PermissionType.ALL].includes(resource)) {
        return true;
      }
      if (
        permType === PermissionType.READ &&
        (permDict[PermissionType.WRITE].includes(resource) || permDict[PermissionType.READ].includes(resource))
      ) {
        return true;
      }
      if (permDict[permType].includes(resource)) {
        return true;
      }
      return false;
    } else {
      if (permDict.DENY && resource.some(r => permDict.DENY.includes(r))) {
        return false;
      }
      if (resource.some(r => permDict[PermissionType.ALL].includes(r))) {
        return true;
      }
      if (resource.some(r => permDict[permType].includes(r))) {
        return true;
      }
      return false;
    }
  }
  /**
   * @deprecated This should exist in the backend api
   */
  static checkTenantAccess(claims: Token, tenantKey: string, global: boolean = false): void {
    if (claims.tenancyOn && !tenantKey && !global) {
      throw new Error('ERROR: When tenancy is enabled you must specify a tenant key');
    }
    if (!claims.tenants.includes(tenantKey)) {
      throw new Error('ERROR: User does not have access to specified tenant');
    }
  }
  static getNestedPolicyData(permsList: string[], policyData: UMIPolicy): AppFeaturePermissions {
    const [level, feature, attr1, attr2] = permsList;
    if (attr2) {
      return policyData[level][feature][attr1][attr2];
    } else if (attr1) {
      policyData[level][feature][attr1];
    } else {
      policyData[level][feature];
    }
  }
  static checkFeaturePermission(policy: UMIPolicy, action: UMIFeatureAction): boolean {
    const permsList = action.feature.split('.');
    const permissions: AppFeaturePermissions = this.getNestedPolicyData(permsList, policy);
    const permDict = UserPermissionsManager.parseAccessibleResources(permissions);
    console.log('PERMS ', permissions);
    return UserPermissionsManager.resourceHasAccess(action.resource, permDict, action.permission);
  }

  static hasConnectActionAccess(policy: UMIPolicy, connectAction: string): boolean {
    return policy.connect.actions[connectAction] ? policy.connect.actions[connectAction].featureOn : false;
  }
  static singleResourceWriteCheck(resourceIdentifier: string, perm: ConnectResourcePermissions): boolean {
    if (!perm[resourceIdentifier]) {
      return false;
    }
    return (
      perm[resourceIdentifier].includes(PermissionType.ALL) || perm[resourceIdentifier].includes(PermissionType.WRITE)
    );
  }
  static parseIntoPermissionsDict(permList: PermissionType[], permDict?: PermissionsDict): PermissionsDict {
    return permDict
      ? {
          [PermissionType.READ]:
            permDict[PermissionType.READ] ||
            permList.includes(PermissionType.ALL) ||
            permList.includes(PermissionType.WRITE) ||
            permList.includes(PermissionType.READ),
          [PermissionType.WRITE]:
            permDict[PermissionType.WRITE] ||
            permList.includes(PermissionType.ALL) ||
            permList.includes(PermissionType.DELETE) ||
            permList.includes(PermissionType.WRITE),
          [PermissionType.DELETE]:
            permDict[PermissionType.DELETE] ||
            permList.includes(PermissionType.ALL) ||
            permList.includes(PermissionType.DELETE),
          [PermissionType.ALL]: permDict[PermissionType.ALL] || permList.includes(PermissionType.ALL),
        }
      : {
          [PermissionType.READ]:
            permList.includes(PermissionType.ALL) ||
            permList.includes(PermissionType.WRITE) ||
            permList.includes(PermissionType.READ),
          [PermissionType.WRITE]:
            permList.includes(PermissionType.ALL) ||
            permList.includes(PermissionType.DELETE) ||
            permList.includes(PermissionType.WRITE),
          [PermissionType.DELETE]: permList.includes(PermissionType.ALL) || permList.includes(PermissionType.DELETE),
          [PermissionType.ALL]: permList.includes(PermissionType.ALL),
        };
  }
  static checkPolicyPermissions(policy: UMIPolicy, actions: UMIFeatureAction[]): boolean {
    if (find(actions, action => !UserPermissionsManager.checkFeaturePermission(policy, action))) {
      return false;
    } else {
      return true;
    }
  }
  static checkPoliciesPermissions(policies: UMIPolicy[], actions: UMIFeatureAction[]): boolean {
    return !policies.map(policy => UserPermissionsManager.checkPolicyPermissions(policy, actions)).includes(false);
  }
}
