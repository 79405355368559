import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { ContactFlowSummary } from 'aws-sdk/clients/connect';
import React, { useContext, useEffect, useReducer } from 'react';
import { Menu } from '@voicefoundry-cloud/vf-omp-shared';
import menuConfig from './menuConfig';
import MenuManagementContext from './MenuManagementContext';
import { menuReducer } from './menuReducer';
import { VF_DEFAULT_QUEUE_FLOW_NAME_REGEX } from '../../defaultUtils/regexUtils';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
export const menuState = {
  ...menuConfig,
};

const MenuManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(menuReducer, menuState);

  useEffect(() => {
    Promise.all([listMenus(), getQueues(), getFlows(), getPrompts(), listQueueTreatments()]);
  }, [api]);
  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) =>
          flow.ContactFlowType === 'CUSTOMER_QUEUE' && !VF_DEFAULT_QUEUE_FLOW_NAME_REGEX.test(flow.Name)
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: { customerQueueFlows },
      });
    }
  }, [state.flowsLoaded]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const listQueueTreatments = async () => {
    dispatch({ type: 'LOADING_QUEUE_TREATMENT', payload: true });
    const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
    dispatch({
      type: 'LIST_QUEUE_TREATMENT',
      payload: { queueTreatmentList },
    });
  };

  const listMenus = async () => {
    dispatch({ type: 'LOADING_MENUS', payload: true });
    const menuList = await api.menu.listMenus();
    dispatch({ type: 'LIST_MENUS', payload: { menuList } });
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getFlows = async () => {
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: {
          flows,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getPrompts = async () => {
    try {
      const prompts = await api.helpers.getAllPrompts();
      dispatch({
        type: 'GET_PROMPTS',
        payload: {
          prompts,
        },
      });
    } catch (err) {
      console.warn('Unable to load prompts', err);
    }
  };
  const getMenu = async (menuName: string) => {
    const workingMenu = await api.menu.getMenu(menuName);
    dispatch({ type: 'GET', payload: { workingMenu } });
  };
  const deleteMenu = async (menuName: string) => {
    dispatch({ type: 'DELETING_MENU', payload: true });
    try {
      await api.menu.deleteMenu(menuName);
      dispatch({ type: 'DELETE', payload: { menuName } });
      await listMenus();
    } catch (err) {
      dispatch({ type: 'DELETING_MENU', payload: false });
    }
  };
  const upsertMenu = (menu: Menu) => {
    // @todo CALL API HERE

    dispatch({ type: 'UPSERT', payload: { menu } });
  };

  const createMenuScaffolding = async (menu: Menu) => {
    await api.menu.createMenu(menu);
    await listMenus();
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return (
    <MenuManagementContext.Provider
      value={{
        ...state,
        upsertMenu,
        getMenu,
        listMenus,
        deleteMenu,
        getQueueMetadata,
        getEntityAttrValue,
        createMenuScaffolding,
      }}>
      {children}
    </MenuManagementContext.Provider>
  );
};

export default MenuManagementContextProvider;
