import { AppContext } from '@vf';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import AppContextPropsType from '@vf/utility/ContextProvider/AppContextPropsType';
import config from './config';
import ConnectUserManagementContext from './ManagementContext';

import { reducer } from './reducer';
import { TenantContext } from 'contexts';
import TenantContextPropTypes from 'contexts/tenant/ContextPropTypes';
import {
  useInfoViewActionsContext,
  fetchStart,
  fetchSuccess,
  fetchError,
} from '../../shared/components/InfoView/InfoViewContext';

export const INITIAL_STATE = {
  ...config,
};

const ConnectUserManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const infoDispatch = useInfoViewActionsContext()!;
  const { tenancyEnabled } = useContext<AppContextPropsType>(AppContext);

  const { loadAdminTenantData } = useContext<TenantContextPropTypes>(TenantContext);

  useEffect(() => {
    if (tenancyEnabled) {
      loadAdminTenantData();
    }
  }, [loadAdminTenantData, tenancyEnabled]);

  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadData = useCallback(() => {
    // Promise.all([fetchConnectUsers()]).then(() =>
    //     infoDispatch(
    //       fetchSuccess('Connect User Management Data has been loaded'),
    //     ),
    //   );
    Promise.all([fetchRoutingProfiles(), fetchSecurityProfiles(), fetchHierarchyGroups()])
      .then(() => fetchConnectUsers())
      .then(() => infoDispatch(fetchSuccess('Connect User Management Data has been loaded')));
  }, []);

  const fetchRoutingProfiles = async () => {
    infoDispatch(fetchStart());
    try {
      const rps = await api.connect.listRoutingProfiles(true);
      console.log(rps);
      dispatch({ type: 'SET_ROUTING_PROFILES', payload: rps });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchHierarchyGroups = async () => {
    infoDispatch(fetchStart());
    try {
      const groups = await api.connect.listUserHierarchyGroupsSummary();
      const hgs = await Promise.all(groups.map(hg => api.connect.getUserHierarchyDetail(hg.Id)));
      dispatch({ type: 'SET_USER_HIERARCHIES', payload: hgs });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchSecurityProfiles = async () => {
    infoDispatch(fetchStart());
    try {
      const sps = await api.connect.listSecurityProfiles();
      dispatch({ type: 'SET_SECURITY_PROFILES', payload: sps });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchConnectUsers = async () => {
    infoDispatch(fetchStart());
    try {
      const users = await api.connect.listUsersDetailed();
      console.log('USERS ', users);
      dispatch({
        type: 'SET_CONNECT_USERS',
        payload: users,
      });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const updateUserRoutingProfile = async (userId: string, routingProfileId: string) => {
    infoDispatch(fetchStart());
    try {
      await api.connect.updateUserRoutingProfile(userId, routingProfileId);
      dispatch({
        type: 'UPDATE_USER_ROUTING_PROFILE',
        payload: {
          userId,
          routingProfileId,
        },
      });
      infoDispatch(fetchSuccess('Routing profile has been updated'));
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const getSecurityProfileNames = (ids: string[]) =>
    ids.map(id => (state.securityProfileDict[id] ? state.securityProfileDict[id].Name : 'NA'));
  const getRoutingProfileName = (id: string) => {
    return state.routingProfileDict[id] ? state.routingProfileDict[id].Name : 'NA';
  };

  const getUserHierarchyNames = (id: string) => {
    if (!id) return '--';
    const userHierarchy = state.userHierarchyDict[id];
    if (!userHierarchy) return 'NA';
    return Object.keys(userHierarchy.HierarchyPath)
      .map(level => userHierarchy.HierarchyPath[level].Name)
      .join(' / ');
  };
  return (
    <ConnectUserManagementContext.Provider
      value={{
        ...state,
        loadData,
        getSecurityProfileNames,
        getRoutingProfileName,
        getUserHierarchyNames,
        updateUserRoutingProfile,
      }}>
      {children}
    </ConnectUserManagementContext.Provider>
  );
};

export default ConnectUserManagementContextProvider;
